/* @jsx jsx */
import { useState, useRef, Fragment, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import TableOfContents from '@kaigo/gatsby-theme-docs/src/components/Docs/TOC';
import Sidebar from '@kaigo/gatsby-theme-docs/src/components/Sidebar';
import Header from '@kaigo/gatsby-theme-docs/src/components/Header';
import Overlay from '@kaigo/gatsby-theme-docs/src/components/Overlay';
import { Container, Main, Children } from '@kaigo/gatsby-theme-docs/src/components/Layout/styles';

import HeaderLocal from "../../../../components/common/header"
import Footer from "../../../../components/common/footer"

export default function Layout({
  children,
  disableTableOfContents,
  title,
  headings,
  lang
}) {
  const contentRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const disableTOC =
    disableTableOfContents === true || !headings || headings.length === 0;

  function handleMenuOpen() {
    setMenuOpen(!isMenuOpen);
  }

  return (
    <Fragment>
      <HeaderLocal />
      <Overlay isMenuOpen={isMenuOpen} onClick={handleMenuOpen} />
      <div style={{height: "20px", width: "100%", backgroundColor: "#D03B38", position: "sticky", top: "0", zIndex: 100}} />
      <Container>
        <Sidebar isMenuOpen={isMenuOpen} lang={lang} />
        <Main>
          <Header handleMenuOpen={handleMenuOpen} />
          {title && (
            <h1
              css={css`
                display: none;

                @media (max-width: 1200px) {
                  display: block;
                }
              `}
            >
              {title}
            </h1>
          )}
          <Children ref={contentRef}>
            {title && (
              <h1
                css={css`
                  @media (max-width: 1200px) {
                    display: none;
                  }
                `}
              >
                {title}
              </h1>
            )}
            {children}
          </Children>
          <TableOfContents
            headings={headings}
            disableTOC={disableTOC}
            contentRef={contentRef}
          />
        </Main>
      </Container>
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableTableOfContents: PropTypes.bool,
  title: PropTypes.string,
  headings: PropTypes.array,
  lang: PropTypes.string
};

Layout.defaultProps = {
  disableTableOfContents: false,
  title: '',
  headings: null,
  lang: '/en/',
};
